<template>
  <router-link
    class="btn"
    :class="{ 'icon-before': iconBefore }"
    :to="{ name: url }"
  >
    <span v-if="iconBefore" class="transform rotate-180">
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        class="w-4 h-4 ml-2"
        viewBox="0 0 24 24"
      >
        <path d="M5 12h14M12 5l7 7-7 7"></path>
      </svg>
    </span>
    <slot></slot>
    <span v-if="!iconBefore">
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        class="w-4 h-4 ml-2"
        viewBox="0 0 24 24"
      >
        <path d="M5 12h14M12 5l7 7-7 7"></path>
      </svg>
    </span>
  </router-link>
</template>

<style lang="scss" scoped>
.btn {
  @apply inline-flex items-center py-2 px-4 border-2 bg-white rounded-sm border-royalblue-400 text-base text-royalblue-900 font-medium leading-base cursor-pointer transition-colors duration-300;

    &:hover,
    &:focus {
      @apply bg-royalblue-400 text-white;

    svg {
      @apply translate-x-1.5;
    }
  }

  &:focus {
    @apply focus:outline-none;
  }

  svg {
    @apply transform-gpu transition-transform duration-300;
  }
}
</style>

<script>
export default {
  props: {
    url: {
      Type: String,
      Required: true,
    },
    iconBefore: {
      Type: Boolean,
      required: false,
    },
  },
};
</script>

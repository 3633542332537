<template>
  <section class="container mb-14 sm:mb-20">
    <div
      class="
        container__inner
        flex
        pt-6
        md:pt-12
        md:flex-row
        flex-col
        md:items-start
        justify-between
      "
    >
      <div
        class="
          lg:flex-grow
          md:w-6/12
          lg:pr-24
          flex flex-col
          md:items-start
          mb-8
          md:mb-0
        "
      >
        <div>
          <h1 class="text-royalblue-900 mb-1">{{ name }}</h1>
          <p
            v-if="qualifications"
            class="h5 text-royalblue-400 mb-1 leading-relaxed"
          >
            {{ qualifications }}
          </p>
          <p v-if="charter || registration" class="text-sm">
            {{ charter }} <span v-if="charter && registration"> | </span>
            {{ registration }}
          </p>
          <slot></slot>
        </div>
        <div class="hidden md:block">
          <base-btn url="Meet The Team" class="mt-6 md:mt-8" :icon-before="true"
            >Back</base-btn
          >
        </div>
      </div>
      <div class="md:w-5/12 lg:w-5/12 w-1/2">
        <img
          class="w-full object-cover object-center rounded"
          :alt="name"
          :src="pic"
        />
        <div class="block md:hidden">
          <base-btn url="Meet The Team" class="mt-8" :icon-before="true"
            >Back</base-btn
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseBtn from "../components/ui/BaseBtn.vue";

export default {
  components: {
    "base-btn": BaseBtn,
  },
  props: {
    name: {
      Type: String,
      Required: true,
    },
    qualifications: {
      Type: String,
      Required: false,
    },
    charter: {
      Type: String,
      Required: false,
    },
    registration: {
      Type: String,
      Required: false,
    },
    bio: {
      Type: String,
      Required: true,
    },
    pic: {
      Type: String,
      Required: true,
    },
  },
};
</script>

<template>
  <team-member-profile
    name="Heather Ross"
    qualifications="BSc (Hons)"
    charter="MCSP"
    registration="HCPC Registered: PH49092"
    :pic="require(`@/assets/images/heather.jpg`)"
  >
    <p>
      Heather qualified in 1995 from the University of East Anglia and has
      worked within the NHS for 20 years.
    </p>
    <p>
      Over that time, she has worked in a number of NHS settings within Dorset,
      Kent and Hampshire. Heather enjoys working with patients and finds it very
      rewarding.
    </p>
    <p>
      She has a wide range of experience in treating people with neurological
      conditions from acute, through rehabilitation as inpatients to long-term
      management of patients in the community and outpatients. Since 2001 she
      has worked within Southampton and surrounding areas with Southampton’s
      Community Neurological Rehabilitation Team as well as well as the Stroke
      Unit and as Team leader on Snowdon Rehabilitation unit at Western
      Community Hospital.
    </p>
    <p>
      As well as being involved with treating patients, Heather assists with
      clinical education in Neurology of Physiotherapists at the University of
      Southampton.
    </p>
    <p>
      Heather has completed many post graduate courses including: Advanced
      Bobath courses, postural management, splinting, Lycra assessment and FES.
      She is enthusiastic about being able to offer a range of treatments.
    </p>
    <p>
      Heather is passionate about enabling people and works closely with
      patients on their goals, offering long and short term rehabilitation
      treatments. “I like to be practical in my treatments, finding the most
      effective treatment for the individual, related to the environment and
      life.
    </p>
    <p>
      In her spare time Heather enjoys spending time on the water sailing,
      especially with her children, gardening and sewing.
    </p>
  </team-member-profile>
</template>

<script>
import TeamMemberProfile from "../../layouts/TeamMemberProfile.vue";
export default {
  name: "Heather",
  components: {
    "team-member-profile": TeamMemberProfile,
  },
};
</script>
